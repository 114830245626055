import React from 'react';

export const numberOfDishes = (
  <svg width='20' height='20' viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path
      d='M14.19 0H5.81C2.17 0 0 2.17 0 5.81V14.18C0 17.83 2.17 20 5.81 20H14.18C17.82 20 19.99 17.83 19.99 14.19V5.81C20 2.17 17.83 0 14.19 0ZM5.63 16.15C5.63 16.56 5.29 16.9 4.88 16.9C4.47 16.9 4.13 16.56 4.13 16.15V14.08C4.13 13.67 4.47 13.33 4.88 13.33C5.29 13.33 5.63 13.67 5.63 14.08V16.15ZM10.75 16.15C10.75 16.56 10.41 16.9 10 16.9C9.59 16.9 9.25 16.56 9.25 16.15V12C9.25 11.59 9.59 11.25 10 11.25C10.41 11.25 10.75 11.59 10.75 12V16.15ZM15.87 16.15C15.87 16.56 15.53 16.9 15.12 16.9C14.71 16.9 14.37 16.56 14.37 16.15V9.93C14.37 9.52 14.71 9.18 15.12 9.18C15.53 9.18 15.87 9.52 15.87 9.93V16.15ZM15.87 6.77C15.87 7.18 15.53 7.52 15.12 7.52C14.71 7.52 14.37 7.18 14.37 6.77V5.8C11.82 8.42 8.63 10.27 5.06 11.16C5 11.18 4.94 11.18 4.88 11.18C4.54 11.18 4.24 10.95 4.15 10.61C4.05 10.21 4.29 9.8 4.7 9.7C8.07 8.86 11.07 7.09 13.45 4.59H12.2C11.79 4.59 11.45 4.25 11.45 3.84C11.45 3.43 11.79 3.09 12.2 3.09H15.13C15.17 3.09 15.2 3.11 15.24 3.11C15.29 3.12 15.34 3.12 15.39 3.14C15.44 3.16 15.48 3.19 15.53 3.22C15.56 3.24 15.59 3.25 15.62 3.27C15.63 3.28 15.63 3.29 15.64 3.29C15.68 3.33 15.71 3.37 15.74 3.41C15.77 3.45 15.8 3.48 15.81 3.52C15.83 3.56 15.83 3.6 15.84 3.65C15.85 3.7 15.87 3.75 15.87 3.81C15.87 3.82 15.88 3.83 15.88 3.84V6.77H15.87Z'
      fill='#071731'
    />
  </svg>
);

export const numberOfFollowers = (
  <svg width='20' height='21' viewBox='0 0 20 21' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path
      d='M15.535 5.77C15.465 5.76 15.395 5.76 15.325 5.77C13.775 5.72 12.545 4.45 12.545 2.89C12.545 1.3 13.835 0 15.435 0C17.025 0 18.325 1.29 18.325 2.89C18.315 4.45 17.085 5.72 15.535 5.77Z'
      fill='#071731'
    />
    <path
      d='M18.795 12.7C17.675 13.45 16.105 13.73 14.655 13.54C15.035 12.72 15.235 11.81 15.245 10.85C15.245 9.84998 15.025 8.89998 14.605 8.06998C16.085 7.86998 17.655 8.14998 18.785 8.89998C20.365 9.93998 20.365 11.65 18.795 12.7Z'
      fill='#071731'
    />
    <path
      d='M4.44504 5.77C4.51504 5.76 4.58504 5.76 4.65504 5.77C6.20504 5.72 7.43504 4.45 7.43504 2.89C7.43504 1.29 6.14504 0 4.54504 0C2.95504 0 1.66504 1.29 1.66504 2.89C1.66504 4.45 2.89504 5.72 4.44504 5.77Z'
      fill='#071731'
    />
    <path
      d='M4.555 10.85C4.555 11.82 4.765 12.74 5.145 13.57C3.735 13.72 2.265 13.42 1.185 12.71C-0.395 11.66 -0.395 9.94998 1.185 8.89998C2.255 8.17998 3.765 7.88998 5.185 8.04998C4.775 8.88998 4.555 9.83998 4.555 10.85Z'
      fill='#071731'
    />
    <path
      d='M10.1251 13.87C10.0451 13.86 9.95505 13.86 9.86505 13.87C8.02505 13.81 6.55505 12.3 6.55505 10.44C6.56505 8.54 8.09505 7 10.0051 7C11.9051 7 13.4451 8.54 13.4451 10.44C13.4351 12.3 11.9751 13.81 10.1251 13.87Z'
      fill='#071731'
    />
    <path
      d='M6.87505 15.94C5.36505 16.95 5.36505 18.61 6.87505 19.61C8.59505 20.76 11.4151 20.76 13.1351 19.61C14.6451 18.6 14.6451 16.94 13.1351 15.94C11.4251 14.79 8.60505 14.79 6.87505 15.94Z'
      fill='#071731'
    />
  </svg>
);

export const saveIcon = (
  <svg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path
      d='M12.89 5.88H5.11C3.4 5.88 2 7.28 2 8.99V20.35C2 21.8 3.04 22.42 4.31 21.71L8.24 19.52C8.66 19.29 9.34 19.29 9.75 19.52L13.68 21.71C14.96 22.41 16 21.8 16 20.35V8.99C16 7.28 14.6 5.88 12.89 5.88Z'
      fill='#071731'
    />
    <path
      d='M22.0001 5.11V16.47C22.0001 17.92 20.9601 18.53 19.6901 17.83L17.7601 16.75C17.6001 16.66 17.5001 16.49 17.5001 16.31V8.99C17.5001 6.45 15.4301 4.38 12.8901 4.38H8.82008C8.45008 4.38 8.19008 3.99 8.36008 3.67C8.88008 2.68 9.92008 2 11.1101 2H18.8901C20.6001 2 22.0001 3.4 22.0001 5.11Z'
      fill='#071731'
    />
  </svg>
);

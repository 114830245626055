import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { URLs } from '../../constants/baseUrls';

const BASE_URL = URLs.DEV_URL;

export const foodContentInfoApi = createApi({
  reducerPath: 'foodcontent',
  baseQuery: fetchBaseQuery({
    baseUrl: `${BASE_URL}/foodcontentinfo`,
  }),
  endpoints: (builder) => ({
    fetchFoodContentInfo: builder.query({
      query: (data) => {
        return {
          url: `all${data ? '?categoryId=' + data : ''}`,
          method: 'GET',
          headers: {
            authorization: `Bearer ${localStorage.getItem('token')}`,
          },
        };
      },
    }),
    addPost: builder.mutation({
      query: (postData) => {
        return {
          url: '/add',
          body: {
            dishName: postData?.dishName,
            restaurant: postData?.restaurant,
            reactionType: postData?.reactionType,
            parentCategory: postData?.parentCategory,
            base64Image: postData?.base64Image,
            foodContentInfo: postData?.foodContentInfo,
          },
          method: 'POST',
          headers: {
            authorization: `Bearer ${localStorage.getItem('token')}`,
          },
        };
      },
    }),
  }),
});

export const { useFetchFoodContentInfoQuery } = foodContentInfoApi;

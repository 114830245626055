/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */
/* eslint-disable no-prototype-builtins */
import React, { useEffect, useState } from 'react';
import Layout from '../components/Layout';
import Header from '../components/Header';
import {
  useDeletePostMutation,
  useFetchPostsQuery,
  useFetchRatingsByRestaurantIdQuery,
  useFetchRestaurantByIdQuery,
  useFetchUserQuery,
} from '../store';
import { callIcon, locationIcon } from '../constants/iconUtils';
import SearchIcon from '../assets/icons/search-normal.svg';
import Foodcard from '../components/Foodcard';
import Modal from '../components/Modal';
import EditPostForm from '../components/EditPostForm';
import { MdOutlineModeEditOutline } from 'react-icons/md';
import CreatePostForm from '../components/CreatePostForm';
import { toast } from 'react-toastify';
import EditProfileForm from '../components/EditProfileForm';
import FoodCardSkeleton from '../components/FoodCardSkeleton';
import RestaurantQuickInfo from '../components/RestaurantQuickInfo';
import RestaurantRatingOverview from '../components/RestaurantRatingOverview';
import RestaurantOperatingHours from '../components/RestaurantOperatingHours';
import Button from '../components/Button';

const Profile = () => {
  const [query, setQuery] = useState({
    page: 1,
    size: 10,
  });
  // const navigate = useNavigate();
  const [showModal, setShowModal] = useState(false);
  const [showCreatePostModal, setShowCreatePostModal] = useState(false);
  const [showEditProfileModal, setShowEditProfileModal] = useState(false);
  const [currentIndex, setCurrentIndex] = useState(-1);
  const [dataIndex, setdataIndex] = useState(0);
  let {
    data: user,
    // isFetching: isUserFetching,
    // error: userError,
  } = useFetchUserQuery();
  let {
    data: restaurant,
    isFetching: isRestaurantFetching,
    // error: restaurantFetchError,
  } = useFetchRestaurantByIdQuery(user?.user?.restaurant);

  let {
    data: postData,
    // error: postError,
    isFetching: isPostsFetching,
  } = useFetchPostsQuery(query);

  let {
    data: restaurantRatingData,
    // error: restaurantRatingDataError,
    // isFetching: isRestaurantRatingDataFetching,
  } = useFetchRatingsByRestaurantIdQuery(user?.user?.restaurant);

  let [deletePostData /* results */] = useDeletePostMutation();

  const handleShowEditModal = (event) => {
    setShowModal(!showModal);
  };
  const handleShowCreatePostModal = (event) => {
    setShowCreatePostModal(!showCreatePostModal);
  };
  const handleShowEditProfileModal = (event) => {
    setShowEditProfileModal(!showEditProfileModal);
  };
  const handleCloseEditProfileModal = (event) => {
    setShowEditProfileModal(false);
  };
  const handleClose = () => {
    setShowModal(false);
  };
  const handleCreatePostModalClose = () => {
    setShowCreatePostModal(false);
  };
  const deletePost = async (postId) => {
    const response = await deletePostData(postId);
    if (response.isSuccess) {
      toast.success('Post deleted successfully');
    } else if (response?.error) {
      toast.error(response?.error?.data?.message);
    }
  };

  const handleMenuOpen = (index) => {
    if (currentIndex === index) {
      setCurrentIndex(-1);
      setdataIndex(index);
    } else {
      setCurrentIndex(index);
      setdataIndex(index);
    }
  };


  const modal = (
    <Modal onClose={handleClose}>
      <EditPostForm
        data={postData?.data[dataIndex]}
        restaurant={restaurant?.restaurant}
        onClose={handleClose}
      />
    </Modal>
  );

  const createPostModal = (
    <Modal onClose={handleCreatePostModalClose}>
      <CreatePostForm restaurant={restaurant?.restaurant} onClose={handleCreatePostModalClose} />
    </Modal>
  );
  const editProfileModal = (
    <Modal onClose={handleCloseEditProfileModal}>
      <EditProfileForm restaurant={restaurant?.restaurant} onClose={handleCloseEditProfileModal} />
    </Modal>
  );

  useEffect(() => {
    if (user?.user?.restaurant) {
      setQuery((prevQuery) => ({
        ...prevQuery,
        restaurant: user?.user?.restaurant,
      }));
    }
  }, [user, postData]);

  return (
    <Layout>
      <Header title='Profile' subtitle="Here is what's happening in your cake account" />
      <div className='flex justify-between'>
        <div className='w-1/4 p-2 bg-[#F1A73C] py-4 rounded-lg'>
          <RestaurantQuickInfo restaurant={restaurant} isRestaurantFetching={isRestaurantFetching} />
          <div className='bg-white p-2 rounded-t-lg'>
            <div className='flex items-center justify-around text-[#8C8994] border-b py-3'>
              <span className='flex flex-col text-sm'>
                <span className='flex items-center pb-2'>
                  <span className='pr-1'>{callIcon}</span> Call
                </span>
                <p>989 787 6567</p>
              </span>
              <span className='flex flex-col text-sm'>
                <span className='flex items-center justify-between pb-2'>
                  <span className='flex'>
                    <span className='pr-1'>{locationIcon}</span>
                    Website
                  </span>
                  <MdOutlineModeEditOutline
                    className='text-lg text hover:cursor-pointer'
                    onClick={handleShowEditProfileModal}
                  />
                </span>
                <p>www.foodaholix.com</p>
              </span>
            </div>
            <RestaurantRatingOverview restaurant={restaurant} restaurantRatingData={restaurantRatingData} />
            <RestaurantOperatingHours restaurant={restaurant} />
          </div>
          <Button
            label='Select Plan'
            className='w-full flex justify-center items-center rounded-b-lg text-sm font-bold text-white bg-[#6CB132] py-2'
          />
        </div>
        <div className='w-3/4'>
          <div className='bg-white mx-3 rounded-lg'>
            <div className='flex justify-end py-6 pr-6 border-b'>
              <Button
                label='Create Post'
                className='bg-[#6CB132] px-6 py-2 text-white font-bold rounded-lg'
                onClick={(event) => handleShowCreatePostModal(event)}
              />
            </div>
            <div className='px-6 pt-6'>
              <input
                style={{
                  backgroundImage: `url(${SearchIcon})`,
                  backgroundRepeat: 'no-repeat',
                  backgroundPosition: '.5rem .65rem',
                }}
                type='text'
                className='bg-[#ebecec33] w-full px-3 pl-9 border py-2 rounded-lg'
                placeholder='Search people, group and messages'
              />
            </div>
            <div>
              <div className='flex mt-6'>
                <div className='w-full bg-white rounded-lg'>
                  <div className='flex flex-wrap justify-around h-screen overflow-y-scroll'>
                    {isPostsFetching
                      ? Array(4)
                          .fill(0)
                          .map((element, index) => <FoodCardSkeleton key={index} />)
                      : postData?.data?.map((item, index) => (
                          <Foodcard
                            key={index}
                            dishImage={item?.imageUrl}
                            dishName={item?.dishName}
                            restaurant={item?.restaurant?.name}
                            cuisine={item?.cuisine?.name}
                            parent={item?.parentCategory?.name}
                            postedBy={item?.createdBy?.username}
                            handleShowEditModal={() => handleShowEditModal(index)}
                            deletePost={() => deletePost(item._id)}
                            bottomBar={true}
                            openMenuHandler={handleMenuOpen}
                            currentIndex={currentIndex}
                            dataIndex={dataIndex}
                            index={index}
                            showEditMenu={true}
                            likeCount={item.likeCount}
                            loveCount={item.loveCount}
                            regretCount={item.regretCount}
                          />
                        ))}
                  </div>
                  <div>{showModal && modal}</div>
                  <div>{showCreatePostModal && createPostModal}</div>
                  <div>{showEditProfileModal && editProfileModal}</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default Profile;

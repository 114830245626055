/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */
import React, { useEffect, useRef } from 'react';
import { FaRegBell } from 'react-icons/fa';
import Spinner from './Spinner';
import Skeleton from 'react-loading-skeleton';
import { AiOutlineCaretDown } from 'react-icons/ai';
import { useFetchNotificationQuery, useFetchUserQuery } from '../store';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { useState } from 'react';
import Notification from './Notification';

const Header = ({ title, subtitle }) => {
  const [openNotification, setOpenNotification] = useState(false);
  let { data: user, isFetching: isUserFetching, error: userError } = useFetchUserQuery();
  const token = localStorage.getItem('token');
  const navigate = useNavigate();
  const handleOpenNotification = () => {
    setOpenNotification(!openNotification);
  };

  const divElement = useRef();

  useEffect(() => {
    const handler = (event) => {
      if (!divElement?.current?.contains(event.target)) {
        setOpenNotification(false);
      }
    };

    document.addEventListener('click', handler, true);

    return () => {
      document.removeEventListener('click', handler);
    };
  }, []);

  useEffect(() => {
    if (token && userError?.status === 401) {
      navigate('/login');
      localStorage.clear();
      toast.error('Session aborted. Please login.');
    }
  }, [isUserFetching]);
  return (
    <div className='flex justify-between items-center my-3'>
      <div>
        <h2 className='text-3xl font-bold'>{title || 'Dashboard'}</h2>
        <p className='text=[#858688]'>
          {subtitle || ""}
        </p>
      </div>
      <div className='flex justify-between items-center mr-4'>
        <div className='relative' ref={divElement}>
          <div
            onClick={handleOpenNotification}
            className='p-1 rounded-md bg-white hover:cursor-pointer'
          >
            <FaRegBell className='text-xl' />
          </div>
          {openNotification && (
            <div className='absolute right-0 w-80 top-8 bg-white py-4 rounded-lg'>
              <Notification openState={openNotification} />
            </div>
          )}
        </div>
        <div className='w-12 h-12 mx-2 bg-slate-700 rounded-3xl flex justify-center items-center'>
          {user ? (
            <img className='rounded-3xl w-12 h-12' src={user?.user?.profilePic} alt='profile' />
          ) : (
            <Spinner />
          )}
        </div>
        <div className='hover:cursor-pointer'>
          <h3 className='flex items-center'>
            {user ? user?.user?.username : <Skeleton />} <AiOutlineCaretDown className='ml-1' />
          </h3>
          <p className='text-xs text-[#858688]'>{user ? 'Admin' : <Skeleton width={60} />}</p>
        </div>
      </div>
    </div>
  );
};

export default Header;

/* eslint-disable react/prop-types */
/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react';
import { numberOfDishes } from '../constants/analyticsIcons';
import { BsArrowUpShort } from 'react-icons/bs';
import Foodcard from '../components/Foodcard';
import {
  useFetchPostsQuery,
  // useFetchQrQuery,
  useFetchUserQuery,
} from '../store';
import Layout from '../components/Layout';
import Header from '../components/Header';
import Modal from '../components/Modal';
import EditPostForm from '../components/EditPostForm';
import Skeleton from 'react-loading-skeleton';
import { URLs } from '../constants/baseUrls';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { useSelector } from 'react-redux';
import FoodCardSkeleton from '../components/FoodCardSkeleton';

const baseUrl = URLs.DEV_URL;

const analyticsCard = [
  {
    title: 'Number of Dishes',
    icon: numberOfDishes,
    value: 40,
    analytics: 0.8,
  },
  {
    title: 'Number of Followers',
    icon: numberOfDishes,
    value: 40,
    analytics: 0.8,
  },
  {
    title: 'Account View',
    icon: numberOfDishes,
    value: 40,
    analytics: 0.8,
  },
  {
    title: 'Saved Dishes',
    icon: numberOfDishes,
    value: 40,
    analytics: 0.8,
  },
];

const AnalyticsCard = ({ data }) => {
  const { title, icon, value, analytics } = data;

  return (
    <div className='w-64 p-2 bg-white rounded-md'>
      <div className='flex items-center'>
        <span className='mr-2 p-1 bg-[#F0F0F0] rounded-md'>{icon}</span>
        <h2 className='text-xl font-[600]'>{title}</h2>
      </div>
      <div className='flex items-center justify-around mt-4 mb-2'>
        <h2 className='text-2xl font-bold'>{value}</h2>
        <div className='flex flex-col items-center'>
          <p className='text-md flex'>
            {analytics}% <BsArrowUpShort className='text-2xl' />
          </p>
          <p className='text-[#8C8994]'>This Week</p>
        </div>
      </div>
    </div>
  );
};

const Dashboard = () => {
  const [query, setQuery] = useState({
    page: 1,
    size: 10,
  });
  const [showModal, setShowModal] = useState(false);
  const [currentItem, setCurrentItem] = useState(0);
  const navigate = useNavigate();

  const { user } = useSelector((state) => state.user);
  let {
    data: postData,
    error: postError,
    isFetching: isPostsFetching,
  } = useFetchPostsQuery(query, { skip: user ? false : true });

  useEffect(() => {
    if (user?.user?.restaurant) {
      setQuery((prevQuery) => ({
        ...prevQuery,
        restaurant: user?.restaurant,
      }));
    }
  }, [isPostsFetching, user]);

  const handleClose = () => {
    setShowModal(false);
  };

  const handleShowEditModal = (event) => {
    setCurrentItem(event);
    setShowModal(!showModal);
  };

  const modal = (
    <Modal onClose={handleClose}>
      <EditPostForm data={postData?.data[currentItem]} onClose={handleClose} />
    </Modal>
  );

  return (
    <Layout>
      <div className='h-full'>
        <div>
          <Header />
        </div>
        <div className='hidden justify-between mr-4 mt-5'>
          {analyticsCard.map((item, index) => (
            <AnalyticsCard key={index} data={item} />
          ))}
        </div>
        <div className='flex mt-6'>
          <div className='w-2/3 bg-white rounded-lg'>
            <h2 className='text-2xl font-bold p-6'>Highlight Missing Info for Dishes</h2>
            <div className='flex flex-wrap justify-around h-[440px] overflow-y-scroll'>
              {isPostsFetching ? (
                Array(10).fill(0).map((element, index) => <FoodCardSkeleton key={index} />)
              ) : (
                postData?.data?.map((item, index) => (
                  <Foodcard
                    key={index}
                    dishImage={item?.imageUrl}
                    dishName={item?.dishName}
                    restaurant={item?.restaurant?.name}
                    cuisine={item?.cuisine?.name}
                    parent={item?.parentCategory?.name}
                    postedBy={item?.createdBy?.username}
                    handleShowEditModal={() => handleShowEditModal(index)}
                    bottomBar={true}
                  />
                ))
              )}
            </div>
          </div>
          <div className='w-1/3 px-3 rounded-lg'>
            <img
              className='rounded-lg'
              src={`${baseUrl}/restaurant/qr?id=${user?.restaurant}`}
              alt=''
            />
          </div>
        </div>
        <div>{showModal && modal}</div>
      </div>
    </Layout>
  );
};

export default Dashboard;

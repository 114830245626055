import moment from 'moment';

export const formatDates = (date) => {
	return `${date.getHours()}:${date.getMinutes()}`;
};

export const getAmPm = (date) => {
	return date.getHours() >= 12 ? 'PM' : 'AM';
};

export const timeSince = (date) => {
	var seconds = Math.floor((new Date() - date) / 1000);

	var interval = seconds / 31536000;

	if (interval > 1) {
		return moment(date).format('Do MMM YYYY');
	}
	interval = seconds / 2592000;
	if (interval > 1) {
		return moment(date).format('Do MMM YYYY');
	}
	interval = seconds / 86400;
	if (interval > 1) {
		return Math.floor(interval) + ' days ago';
	}
	interval = seconds / 3600;
	if (interval > 1) {
		return Math.floor(interval) + ' hours ago';
	}
	interval = seconds / 60;
	if (interval > 1) {
		return Math.floor(interval) + ' minutes ago';
	}
	return Math.floor(seconds) + ' seconds ago';
};

/* eslint-disable no-prototype-builtins */
/* eslint-disable react/prop-types */
import React from 'react';
import { MdOutlineModeEditOutline } from 'react-icons/md';
import { clockIcon } from '../constants/iconUtils';
import { formatDates, getAmPm } from '../utils/convertDates';

const RestaurantOperatingHours = ({ restaurant }) => {
  const daysOfTheWeek = [
    'Sunday',
    'Monday',
    'Tuesday',
    'Wednesday',
    'Thursday',
    'Friday',
    'Saturday',
  ];
  const openingHours = {
    Monday: {},
    Tuesday: {},
    Wednesday: {},
    Thursday: {},
    Friday: {},
    Saturday: {},
    Sunday: {},
  };

  daysOfTheWeek.forEach((day) => {
    if (restaurant?.restaurant?.openingHours?.hasOwnProperty(day)) {
      openingHours[day] = restaurant?.restaurant?.openingHours[day][0];
    } else {
      openingHours[day] = {};
    }
  });
  return (
    <div className='py-4'>
      <div className='text-sm font-bold text-[#8c8994] flex justify-between items-center py-2'>
        Operating Hours <MdOutlineModeEditOutline className='text-lg' />
      </div>
      {Object.entries(openingHours).map(([key, value]) => (
        <div key={key} className='flex justify-between items-center font-bold py-1 text-[#8C8994]'>
          <p className='flex text-sm'>
            <span className='mr-1'>{clockIcon}</span>
            <span>{key}</span>
          </p>
          <p className='text-sm'>
            {Object.entries(value).length > 0
              ? `${formatDates(new Date(value.start))} ${getAmPm(
                  new Date(value.start),
                )} - ${formatDates(new Date(value.end))} ${getAmPm(new Date(value.end))}`
              : 'Closed'}
          </p>
        </div>
      ))}
    </div>
  );
};

export default RestaurantOperatingHours;

/* eslint-disable no-unused-vars */
/* eslint-disable no-empty */
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { URLs } from '../../constants/baseUrls';
import { setUser } from '../slices/userSlice';

const BASE_URL = URLs.DEV_URL;

export const userApi = createApi({
  reducerPath: 'users',
  baseQuery: fetchBaseQuery({
    baseUrl: `${BASE_URL}/users`,
  }),
  endpoints: (builder) => ({
    fetchUser: builder.query({
      query: (data) => {
        return {
          url: 'me',
          method: 'GET',
          headers: {
            authorization: `Bearer ${localStorage.getItem('token')}`,
          },
        };
      },
      transformResponse: (result) => result,
      async onQueryStarted(data, { dispatch, queryFulfilled }) {
        try {
          const data = await queryFulfilled;
          const { user } = data.data;
          dispatch(setUser(user));
        } catch (error) {}
      },
    }),
    fetchNotification: builder.query({
      query: (data) => {
        return {
          url: 'notifications',
          method: 'GET',
          headers: {
            authorization: `Bearer ${localStorage.getItem('token')}`,
          },
        };
      },
    }),
    submitContactUsDetails: builder.mutation({
      query: (formData) => {
        return {
          url: 'contactUs',
          method: 'POST',
          body: formData,
        };
      },
    }),
  }),
});

export const { useFetchUserQuery, useFetchNotificationQuery, useSubmitContactUsDetailsMutation } = userApi;

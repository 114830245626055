/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */
import React, { useState } from 'react';
import {
  BsThreeDotsVertical,
  // BsChevronDown,
  // BsChevronUp,
} from 'react-icons/bs';
import { MdOutlineModeEditOutline } from 'react-icons/md';
import { RiDeleteBin6Line } from 'react-icons/ri';
import { dishCover, like, location, sad, smile } from '../constants/dishCardIcons';

function convert(value) {
  if (value >= 1000000) {
    value = value / 1000000 + 'M';
  } else if (value >= 1000) {
    value = value / 1000 + 'K';
  }
  return value;
}

const Foodcard = ({
  postId,
  dishImage,
  dishName,
  restaurant,
  cuisine,
  price,
  postedBy,
  likeCount,
  loveCount,
  regretCount,
  parent,
  bottomBar,
  handleShowEditModal,
  deletePost,
  currentIndex,
  index,
  openMenuHandler,
  showEditMenu,
}) => {
  const [seeMore /* setSeeMore */] = useState(false);

  // const setSeeMoreHandle = () => {
  // 	setSeeMore(!seeMore);
  // };

  const shouldOpenMenu = index === currentIndex;

  const addTag = <span className='text-[#6CB132] text-xs font-bold'>ADD+</span>;
  const setDefaultUrl = (event) => {
    event.target.src = 'https://apis-dev.foodaholix.com/uploads/commonImages/no-photo-post.png';
  };

  return (
    <div className='bg-white w-80 p-4 shadow-lg rounded-lg mb-3'>
      <div className='flex justify-between items-center'>
        <div className='w-1/4 h-full mr-2 rounded'>
          <img
            src={dishImage}
            alt='dish'
            className='rounded-md h-full w-full object-cover'
            onError={(event) => setDefaultUrl(event)}
          />
        </div>
        <div className='w-3/4'>
          <div className='flex justify-between items-center'>
            <h3 className='font-bold'>
              {dishName
                ? dishName.length > 12
                  ? dishName.slice(0, 12).trim() + '...'
                  : dishName
                : addTag}
            </h3>
            {showEditMenu && (
              <div className='relative'>
                <BsThreeDotsVertical
                  className='hover:cursor-pointer'
                  onClick={() => openMenuHandler(index)}
                />
                {shouldOpenMenu && (
                  <div className='w-24 absolute right-0 top-5 bg-white rounded-md border shadow-lg py-1'>
                    <p
                      className='border-b border-b-black px-3 py-1 flex items-center hover:cursor-pointer'
                      onClick={() => {
                        handleShowEditModal();
                        openMenuHandler(index);
                      }}
                    >
                      <MdOutlineModeEditOutline className='mr-2' /> Edit
                    </p>
                    <p
                      className='px-3 flex py-1 items-center hover:cursor-pointer'
                      onClick={deletePost}
                    >
                      <RiDeleteBin6Line className='mr-2' /> Delete
                    </p>
                  </div>
                )}
              </div>
            )}
          </div>
          <div className='flex justify-between items-center'>
            <p>
              In{' '}
              <span className='text-[#6CB132]'>
                {restaurant
                  ? restaurant.length > 8
                    ? restaurant.slice(0, 8).trim() + '...'
                    : restaurant
                  : addTag}
              </span>
            </p>
            <p className='price'>{price ? `$${price}` : addTag}</p>
          </div>
          <div className='flex justify-between items-center'>
            <p className='flex items-center'>
              {dishCover}
              <span className='ml-2'>{parent ? parent : addTag}</span>
            </p>
            <p className='flex items-center'>
              {location}
              <span className='ml-2'>{cuisine ? cuisine : addTag}</span>
            </p>
          </div>
          {/* <div className='flex justify-between items-center pt-2'>
						<p className='text-xs'>
							Posted by{' '}
							<span className='text-[#6CB132] font-bold'>
								{postedBy ? postedBy : addTag}
							</span>
						</p>
						<p
							className='flex items-center hover:cursor-pointer'
							onClick={setSeeMoreHandle}
						>
							<span className='pr-1 text-xs'>
								See More
							</span>
							{!seeMore ? (
								<BsChevronDown className='text-xs' />
							) : (
								<BsChevronUp className='text-xs' />
							)}
						</p>
					</div> */}
        </div>
      </div>
      {seeMore && (
        <div className='info'>
          <div className='allergy'>
            <p>
              <span>Allergy Info:</span> gluten free, nuts, etc.
            </p>
            <p>
              <span>Content Info:</span> Vegan, Vegeterian, Chicken, etc.
            </p>
          </div>
          <div className='hashtag'>
            <p>
              <span>Hashtag:</span> gluten free, nuts, etc.
            </p>
          </div>
        </div>
      )}
      {bottomBar && (
        <div className='flex justify-between mt-4'>
          <div className='flex items-center w-1/3 justify-center bg-[#F8FAFC] py-2 rounded-l-lg'>
            <span className='pr-1'>{like}</span> {convert(loveCount || 0)}
          </div>
          <div className='flex items-center w-1/3 justify-center bg-[#F8FAFC] py-2'>
            <span className='pr-1'>{smile}</span> {convert(likeCount || 0)}
          </div>
          <div className='flex items-center w-1/3 justify-center bg-[#F8FAFC] py-2 rounded-r-lg'>
            <span className='pr-1'>{sad}</span> {convert(regretCount || 0)}
          </div>
        </div>
      )}
    </div>
  );
};

export default Foodcard;

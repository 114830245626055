import React from 'react';

const Spinner = () => (
  <svg viewBox="0 0 50 50" className='spinner'>
    <circle
      className="path"
      cx="25"
      cy="25"
      r="20"
      fill="none"
      strokeWidth="6"
    />
  </svg>
);
  

export default Spinner;
/* eslint-disable react/prop-types */
import React from 'react';
import Sidebar from './Sidebar';

const Layout = ({ children }) => {
  return (
    <div className='flex justify-between bg-[#F8FAFC]'>
      <Sidebar />
      <div className='ml-64 w-4/5 pb-3'>{children}</div>
    </div>
  );
};

export default Layout;

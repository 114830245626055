/* eslint-disable react/prop-types */
import React, { useState } from 'react';
import Foodcard from './Foodcard';
import Button from './Button';
import {
  useEditPostMutation,
  // useFetchCuisineByCityQuery,
  useFetchParentCategoryByRestaurantQuery,
} from '../store';
import { convertBase64 } from './../utils/convertBase64';
import { toast } from 'react-toastify';

const EditPostForm = ({ data, restaurant, onClose }) => {
  const {
    data: parentCategory,
    // error: parentCategoryError,
    // isFetching: isParentCategoryFetching,
  } = useFetchParentCategoryByRestaurantQuery(restaurant?.id);
  console.log(parentCategory);
  const [formData, setFormData] = useState({
    price: '',
    image: '',
    postId: data?._id,
    parentCategory: parentCategory?.id,
  });
  // const { data: cuisineOptions } = useFetchCuisineByCityQuery(
  // 	restaurant?.city?._id,
  // );

  const [editResponse /* results */] = useEditPostMutation();
  const handleFormOnChange = async ({ target: { name, value, files } }) => {
    console.log(name, value);
    if (!files) {
      if (name === 'cuisine') {
        setFormData({ ...formData, [name]: [value] });
      } else {
        setFormData({ ...formData, [name]: value });
      }
    } else {
      const base64Image = await convertBase64(files[0]);
      setFormData({ ...formData, [name]: base64Image });
    }
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    const response = await editResponse(formData);
    if (response?.data?.post) {
      toast.success('Post updated successfully');
      onClose();
    } else if (response?.error) {
      toast.error(response?.error?.data);
    }
  };
  return (
    <div>
      <Foodcard
        dishImage={data?.imageUrl}
        dishName={data?.dishName}
        restaurant={data?.restaurant?.name}
        cuisine={data?.cuisine?.name}
        parent={data?.parentCategory?.name}
        postedBy={data?.createdBy?.username}
        price={formData?.price || data?.price}
      />
      <form>
        <div>
          <input
            className='w-full p-2 border border-[#8C8994] rounded-lg mb-4'
            type='number'
            name='price'
            htmlFor='price'
            placeholder='Price'
            onChange={handleFormOnChange}
          />
          <label id='price' hidden>
            Price
          </label>
        </div>
        <div>
          <select
            className='w-full p-2 border border-[#8C8994] rounded-lg mb-4'
            type='text'
            name='parentCategory'
            htmlFor='parentCategory'
            placeholder='Curries'
            onChange={handleFormOnChange}
          >
            <option>Select Category</option>
            {parentCategory?.categories?.map((element, index) => (
              <option key={index} className='py-2' value={element._id}>
                {element.name}
              </option>
            ))}
          </select>
          <label id='parentCategory' hidden>
            Category
          </label>
        </div>
        {/* <div>
						<select
							className='w-full p-2 border border-[#8C8994] rounded-lg mb-4'
							type='select'
							name='cuisine'
							htmlFor='cuisine'
							placeholder='cuisine'
							onChange={handleFormOnChange}
						>
							<option>Select Option</option>
							{cuisineOptions?.cuisines?.map(
								(element) => (
									<option
										className='py-2'
										value={element.name}
									>
										{element.name}
									</option>
								),
							)}
						</select>
						<label id='cuisine' hidden>
							Cuisine
						</label>
					</div> */}
        <div>
          <input
            className='w-full p-2 border border-[#8C8994] rounded-lg mb-4'
            type='file'
            name='image'
            htmlFor='image'
            placeholder='Upload Picture'
            onChange={handleFormOnChange}
          />
          <label id='image' hidden>
            File Upload
          </label>
        </div>
        <div className='flex justify-between'>
          <Button
            type='button'
            className='bg-[#8C8994] px-8 text-white rounded-lg py-2'
            label='Cancel'
            onClick={onClose}
          />
          <Button
            className='bg-[#6CB132] px-8 text-white rounded-lg py-2'
            label='Submit'
            onClick={(event) => handleSubmit(event)}
          />
        </div>
      </form>
    </div>
  );
};

export default EditPostForm;

/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */
import React from 'react';
import Spinner from './Spinner';

const Button = ({ label, icon, leftIcon, rightIcon, color, isFetching, ...rest }) => {
  return (
    <button {...rest}>
      {isFetching ? (
        <Spinner />
      ) : (
        <>
          {leftIcon && <span className='left-icon'>{leftIcon}</span>}
          <span
            className='label'
            style={{
              color: color && color,
            }}
          >
            {label}
          </span>
          {rightIcon && <span className='right-icon'>{rightIcon}</span>}
        </>
      )}
    </button>
  );
};

export default Button;

const { createSlice } = require("@reduxjs/toolkit");

const postSlice = createSlice({
  name: 'post',
  initialState: [],
  reducers: {
    setPosts: (state, action) => {
      state.post = {...action.payload}
    }
  }
})

export default postSlice.reducer;
export const { setPosts } = postSlice.actions;
/* eslint-disable react/prop-types */
import React from 'react';
import Spinner from './Spinner';
import Skeleton from 'react-loading-skeleton';

const RestaurantQuickInfo = ({ restaurant, isRestaurantFetching }) => {
  const restaurantName = restaurant?.restaurant?.name ? restaurant?.restaurant?.name : '';
  const restaurantCityName = restaurant?.restaurant?.city?.name
    ? restaurant?.restaurant?.city?.name
    : '';
  const restaurantStateName = restaurant?.restaurant?.state?.name
    ? restaurant?.restaurant?.state?.name
    : '';
  const restaurantCountryName = restaurant?.restaurant?.country?.name
    ? restaurant?.restaurant?.country?.name
    : '';
  return (
    <div className='flex items-center'>
      <div className='w-1/4 pr-1'>
        <div className='rounded-full w-14 h-14 border flex justify-center items-center'>
          {restaurant || !isRestaurantFetching ? (
            <img
              className='rounded-full w-14 h-14 border'
              src={restaurant?.restaurant?.imageUrl}
              alt='profile'
            />
          ) : (
            <Spinner />
          )}
        </div>
      </div>
      <div className='w-3/4'>
        <div>
          {!isRestaurantFetching ? (
            <h2 className='text-lg font-bold text-white'>{restaurantName}</h2>
          ) : (
            <Skeleton />
          )}
        </div>
        {!isRestaurantFetching ? (
          <p className='text-white'>{`${restaurantCityName}, ${restaurantStateName}, ${restaurantCountryName}`}</p>
        ) : (
          <Skeleton />
        )}
      </div>
    </div>
  );
};

export default RestaurantQuickInfo;

/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */
import React from 'react';
import { useFetchNotificationQuery } from '../store';
import { timeSince } from '../utils/convertDates';
import Skeleton from 'react-loading-skeleton';

const Notification = ({ openState }) => {
  let {
    data: userNotification,
    isFetching: isUserNotificationFetching,
    error: userNotificationError,
  } = useFetchNotificationQuery();

  return (
    <div className='h-96 overflow-y-scroll pl-4 pb-3'>
      {isUserNotificationFetching
        ? new Array(10).fill(0).map((element, index) => {
            return (
              <div key={index} style={{ fontSize: 20, lineHeight: 2 }}>
                <h1>
                  <Skeleton />
                </h1>
                <Skeleton />
              </div>
            );
          })
        : userNotification?.notifications
            ?.slice()
            ?.sort((a, b) => new Date(b?.date) - new Date(a?.date))
            ?.map((element, index) => (
              <div key={index} className='pb-1 mb-3 border-b'>
                <div className='flex justify-between items-start pt-1'>
                  <h3 className='w-2/3 text-md font-bold line-clamp-1'>
                    {element?.notificationText}
                  </h3>
                  <span className='w-1/3 text-sm text-[#8C8994] font-medium pt-1 flex justify-end'>
                    {timeSince(new Date(element?.date))}
                  </span>
                </div>
                <p className='text-[#8C8994] text-sm'>{element?.notificationBody}</p>
              </div>
            ))}
    </div>
  );
};
export default Notification;

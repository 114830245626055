/* eslint-disable no-unused-vars */
import React, { useState } from 'react';
import Header from '../components/Header';
import Layout from '../components/Layout';

const Promotion = () => {
  const [payment, setPayment] = useState('month');
  const handleClick = (data) => {
    setPayment(data);
  };
  return (
    <Layout>
      {/*<Header title='Promotions' />
       <div className="bg-white rounded-lg">
        <div className="px-2 py-4 flex justify-between items-center">
          <h2 className="text-xl font-bold">
            Choose a Plan that's right for you.
          </h2>
          <div className="p-1 px-2 rounded-lg bg-[#F8F8FF]">
            <button
              className={`p-1 rounded-lg px-2 ${
                payment === "month" ? "bg-[#071731] text-white" : ""
              }`}
              onClick={() => handleClick("month")}
            >
              Pay Monthly
            </button>
            <button
              className={`p-1 rounded-lg px-2 ${
                payment === "year" ? "bg-[#071731] text-white" : ""
              }`}
              onClick={() => handleClick("year")}
            >
              Pay Yearly
            </button>
          </div>
        </div>
      </div> */}
      <div className="h-screen flex justify-center items-center text-5xl">
        <p className="w-1/2 text-center font-bold">
          We are working hard to bring this feature to you as quick as possible.
        </p>
      </div>
    </Layout>
  );
};

export default Promotion;

/* eslint-disable no-empty */
/* eslint-disable no-unused-vars */
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { setUser } from '../slices/userSlice';
import { URLs } from '../../constants/baseUrls';

const BASE_URL = URLs.DEV_URL;

export const authApi = createApi({
  reducerPath: 'auth',
  baseQuery: fetchBaseQuery({
    baseUrl: `${BASE_URL}/auth`,
  }),
  endpoints: (builder) => ({
    registerUser: builder.mutation({
      query: (data) => {
        return {
          url: 'register',
          method: 'POST',
          body: {},
        };
      },
    }),
    loginUser: builder.mutation({
      query: (data) => {
        return {
          url: 'signin',
          method: 'POST',
          body: data,
        };
      },
      transformResponse: (result) => result,
      async onQueryStarted(data, { dispatch, queryFulfilled }) {
        try {
          const data = await queryFulfilled;
          const { user } = data.data;
          if (user?.role === 'restaurantOwner') {
            localStorage.setItem('token', data.data.token);
            dispatch(setUser(user));
          }
        } catch (error) {}
      },
    }),
  }),
});

export const { useRegisterUserMutation, useLoginUserMutation } = authApi;

/* eslint-disable react/prop-types */
import React from 'react';

import Logo from '../assets/logo.svg';
import { /* Analytics, */ Browser, Contact, /* Dollar, Help, */ Profile } from '../constants/menuIcons';
import { Link, useNavigate } from 'react-router-dom';

const menuItems = [
  {
    name: 'Dashboard',
    link: '/',
    imageUrl: Browser,
  },
  {
    name: 'Profile',
    link: '/profile',
    imageUrl: Profile,
  },
  // {
  //   name: 'Analysis',
  //   link: '/analysis',
  //   imageUrl: Analytics,
  // },
  // {
  //   name: 'Promotions',
  //   link: '/promotions',
  //   imageUrl: Dollar,
  // },
  // {
  //   name: 'Help',
  //   link: '/help',
  //   imageUrl: Help,
  // },
  {
    name: 'Contact Us',
    link: '/contact',
    imageUrl: Contact,
  },
];

const SidebarButtons = ({ name, imageUrl, active, link }) => {
  return (
    <Link
      to={link}
      className={`my-1 flex w-full p-2 hover:cursor-pointer hover:bg-[#6CB132;] rounded-md text-[#858688] hover:text-white items-center ${
        active ? 'bg-[#6CB132] text-white' : ''
      }`}
    >
      <div className='w-4'>{imageUrl}</div>
      <p className='text-md px-4'>{name}</p>
    </Link>
  );
};

const Sidebar = () => {
  const navigate = useNavigate();
  const handleLogout = () => {
    localStorage.removeItem('token');
    localStorage.removeItem('role');

    navigate('/login');
  };
  return (
    <div className='w-1/6 bg-white text-[#858688] h-screen flex flex-col justify-between fixed'>
      <div>
        <div className='flex justify-center items-center py-5'>
          <img src={Logo} alt='logo' className='h-20' />
        </div>
        <div className='w-4/5 mx-auto'>
          {menuItems.map((item, index) => (
            <SidebarButtons
              key={index}
              name={item.name}
              imageUrl={item.imageUrl}
              link={item.link}
              active={item.link === window.location.pathname && 'active'}
            />
          ))}
        </div>
      </div>
      <div className='w-4/5 mx-auto' onClick={handleLogout}>
        <SidebarButtons name='Logout' />
      </div>
    </div>
  );
};

export default Sidebar;

import React from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';

import { ToastContainer } from 'react-toastify';
import Dashboard from './pages/Dashboard';
import LoginPage from './pages/Login';
import Profile from './pages/Profile';
import Analysis from './pages/Analysis';
import { useSelector } from 'react-redux';
import Promotion from './pages/Promotion';
import SignUpPage from './pages/SignUp';
import ContactUs from './pages/ContactUs';

function App() {
  const token = localStorage.getItem('token');
  const { user } = useSelector((state) => state.user);

  return (
    <div className='h-screen'>
      <ToastContainer />
      <Routes path='/'>
        <Route index element={token || user ? <Dashboard /> : <Navigate to='/login' />} />
        <Route path='profile' element={token || user ? <Profile /> : <Navigate to='/login' />} />
        <Route path='analysis' element={token || user ? <Analysis /> : <Navigate to='/login' />} />
        <Route
          path='promotions'
          element={token || user ? <Promotion /> : <Navigate to='/login' />}
        />
        <Route path='help' element={token || user ? <Analysis /> : <Navigate to='/login' />} />
        <Route path='contact' element={token || user ? <ContactUs /> : <Navigate to='/login' />} />
        <Route path='login' element={<LoginPage />} />
        <Route path='sign-up' element={<SignUpPage />} />
      </Routes>
    </div>
  );
}

export default App;

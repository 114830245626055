import React, { useEffect, useState } from "react";

import { BsFacebook } from "react-icons/bs";
import { FcGoogle } from "react-icons/fc";
import { BsApple } from "react-icons/bs";
import { useLoginUserMutation } from "../store";
import { toast } from "react-toastify";
import { Link, useNavigate } from "react-router-dom";
import Button from "../components/Button";

import background from '../assets/login/loginbg.svg';
import { useSelector } from "react-redux";

const Login = () => {
  const [loginUser, { isLoading, isError, error, isSuccess }] =
    useLoginUserMutation();
  const [loginData, setloginData] = useState({
    username: null,
    password: null,
  });
  const navigate = useNavigate();
  const {user} = useSelector((state) => state.user)

  const handleLoginOnChange = (event) => {
    if (event.target.name === "username") {
      setloginData({ ...loginData, username: event.target.value });
    }
    if (event.target.name === "password") {
      setloginData({ ...loginData, password: event.target.value });
    }
  };

  const loginOnSubmit = (event) => {
    event.preventDefault();
    loginUser(loginData);
  };

  useEffect(() => {
    if (isSuccess) {
      if(user?.role === 'restaurantOwner') {
        console.log(user);
        toast.success("Logged in Successfully");
        navigate('/');
      } else {
        toast.error('Wrong Credentials');
      }
    }
    if (isError) {
      if (Array.isArray(error.data.error)) {
        error.data.error.forEach((el) =>
          toast.error(el.message, {
            position: "top-right",
          })
        );
      } else {
        toast.error(error.data.message, {
          position: "top-right",
        });
      }
    }
  }, [error, isError, user]);

  return (
      <div className="flex justify-center w-full" style={{backgroundImage: `url(${background})`}}>
      <div className="p-4 my-10 bg-[#FFFFFF] rounded-lg">
        <h3 className="text-3xl font-bold text-black my-3">Log in</h3>
        <div className="w-80">
          <form>
            <input
              className="w-full p-2 border border-[#8C8994] rounded-lg mb-4"
              type="text"
              name="username"
              htmlFor="username"
              placeholder="Username"
              onChange={handleLoginOnChange}
            />
            <label id="username" hidden>
              Username
            </label>

            <input
              className="w-full p-2 border border-[#8C8994] rounded-lg mb-4"
              type="password"
              name="password"
              htmlFor="password"
              placeholder="Password"
              onChange={handleLoginOnChange}
            />
            <label id="password" hidden>
              Password
            </label>

            <div className="flex justify-between">
              <div className="checkbox-input">
                <input className="mr-2 checked:bg-[#6CB132]" type="checkbox" name="remember" />
                <label className="checkbox-label" htmlFor="remember">
                  Remember me
                </label>
              </div>
              <a className="text-[#6CB132]" href="/">
                Forgot password?
              </a>
            </div>

            <Button
              label="Submit"
              className="bg-[#6CB132] w-full p-3 my-4 rounded-lg"
              color="white"
              onClick={loginOnSubmit}
              isFetching={isLoading}
              disabled={isLoading}
            />
          </form>
          <Link className="flex justify-center items-center mb-4 text-[#6CB132]" to="/">
            Eula & Privacy Policy
          </Link>
          <div className="flex justify-around items-center text-xl text-[#8C8994]">
            <hr className="w-1/3 border border-[#8C8994]" />
            Or
            <hr className="w-1/3 border border-[#8C8994]" />
          </div>
          <Button
            className="w-full p-3 my-2 rounded-lg flex justify-center items-center shadow-lg text-[#8C8994]"
            label="Login with Apple"
            leftIcon={<BsApple size={25} className="mr-2 text-black" />}
          />
          <Button
            className="w-full p-3 my-2 rounded-lg flex justify-center items-center shadow-lg text-[#8C8994]"
            label="Login with Google"
            leftIcon={<FcGoogle size={25} className="mr-2 text-black" />}
          />
          <Button
            className="w-full p-3 my-2 rounded-lg flex justify-center items-center shadow-lg text-[#8C8994]"
            label="Login with Facebook"
            leftIcon={<BsFacebook size={25} className="mr-2 text-[#1877F2]" />}
          />
          <Link to="/sign-up" className="flex justify-center items-center my-3 text-[#F1A73C]">
            Create new account
          </Link>
        </div>
      </div>
      </div>
  );
};

export default Login;
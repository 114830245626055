/* eslint-disable no-empty */
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { URLs } from '../../constants/baseUrls';
import { setPosts } from '../slices/postSlice';

const BASE_URL = URLs.DEV_URL;

export const postApi = createApi({
  reducerPath: 'posts',
  baseQuery: fetchBaseQuery({
    baseUrl: `${BASE_URL}/post`,
  }),
  endpoints: (builder) => ({
    fetchPosts: builder.query({
      query: (data) => {
        return {
          url: 'getAll',
          method: 'GET',
          headers: {
            authorization: `Bearer ${localStorage.getItem('token')}`,
          },
          params: {
            restaurant: data.restaurant,
            pageNo: data?.pageNo ? data?.pageNo : 1,
            size: data?.size ? data?.size : 10,
          },
        };
      },
      transformResponse: (result) => result,
      async onQueryStarted(data, { dispatch, queryFulfilled }) {
        try {
          const data = await queryFulfilled;
          dispatch(setPosts(data.data));
        } catch (error) {}
      },
    }),
    addPost: builder.mutation({
      query: (postData) => {
        return {
          url: 'add',
          body: {
            dishName: postData?.dishName,
            restaurant: postData?.restaurant,
            reactionType: postData?.reactionType,
            parentCategory: postData?.parentCategory,
            base64Image: postData?.base64Image,
            foodContentInfo: postData?.foodContentInfo,
            foodContentInfoSubcategory: postData?.foodSubCategory,
          },
          method: 'POST',
          headers: {
            authorization: `Bearer ${localStorage.getItem('token')}`,
          },
        };
      },
    }),
    editPost: builder.mutation({
      query: (postData) => {
        return {
          url: 'updateForUser',
          body: postData,
          method: 'PATCH',
          headers: {
            authorization: `Bearer ${localStorage.getItem('token')}`,
          },
        };
      },
    }),
    deletePost: builder.mutation({
      query: (postData) => {
        return {
          url: '/delete',
          body: {
            postIds: postData,
          },
          method: 'POST',
          headers: {
            authorization: `Bearer ${localStorage.getItem('token')}`,
          },
        };
      },
    }),
  }),
});

export const {
  useFetchPostsQuery,
  useAddPostMutation,
  useDeletePostMutation,
  useEditPostMutation,
} = postApi;

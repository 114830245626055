/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */
import React, { useState } from 'react';
import Button from './Button';
import { convertBase64 } from '../utils/convertBase64';
import { toast } from 'react-toastify';

const EditProfileForm = ({ restaurant, onClose }) => {
  const [formData, setFormData] = useState({
    phone: '',
    website: '',
    image: '',
  });
  const handleFormOnChange = async ({ target: { name, value, files } }) => {
    console.log(name, value);
    if (!files) {
      setFormData({ ...formData, [name]: [value] });
    } else {
      const base64Image = await convertBase64(files[0]);
      setFormData({ ...formData, [name]: base64Image });
    }
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
  };
  return (
    <div>
      <h3 className='text-2xl font-bold mb-4'>Edit Profile</h3>
      <form>
        <div>
          <input
            className='w-full p-2 border border-[#8C8994] rounded-lg mb-4'
            type='text'
            name='phone'
            htmlFor='phone'
            placeholder='Mobile Number'
            onChange={handleFormOnChange}
          />
          <label id='phone' hidden>
            Mobile Number
          </label>
        </div>
        <div>
          <input
            className='w-full p-2 border border-[#8C8994] rounded-lg mb-4'
            type='text'
            name='website'
            htmlFor='website'
            placeholder='Website'
            onChange={handleFormOnChange}
          />
          <label id='website' hidden>
            Website
          </label>
        </div>
        <div>
          <input
            className='w-full p-2 border border-[#8C8994] rounded-lg mb-4'
            type='file'
            name='image'
            htmlFor='image'
            placeholder='Upload Picture'
            onChange={handleFormOnChange}
          />
          <label id='image' hidden>
            File Upload
          </label>
        </div>
        <div className='flex justify-between'>
          <Button
            type='button'
            className='bg-[#8C8994] px-8 text-white rounded-lg py-2'
            label='Cancel'
            onClick={onClose}
          />
          <Button
            className='bg-[#6CB132] px-8 text-white rounded-lg py-2'
            label='Submit'
            onClick={(event) => handleSubmit(event)}
          />
        </div>
      </form>
    </div>
  );
};

export default EditProfileForm;

import React from "react";
import Layout from "../components/Layout";

const Analysis = () => {
  return (
    <Layout>
      <div className="h-screen flex justify-center items-center text-5xl">
        <p className="w-1/2 text-center font-bold">
          We are working hard to bring this feature to you as quick as possible.
        </p>
      </div>
    </Layout>
  );
};

export default Analysis;

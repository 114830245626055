import { configureStore } from '@reduxjs/toolkit';
import { setupListeners } from '@reduxjs/toolkit/query';

import userReducer from './slices/userSlice';
import postReducer from './slices/postSlice';
import { setUser } from './slices/userSlice';
import { setPosts } from './slices/postSlice';
import { authApi } from './apis/authApi';
import { postApi } from './apis/postApi';
import { userApi } from './apis/userApi';
import { restaurantApi } from './apis/restaurantApi';
import { foodContentInfoApi } from './apis/foodContentInfo';

export const store = configureStore({
  reducer: {
    user: userReducer,
    post: postReducer,
    [authApi.reducerPath]: authApi.reducer,
    [postApi.reducerPath]: postApi.reducer,
    [userApi.reducerPath]: userApi.reducer,
    [restaurantApi.reducerPath]: restaurantApi.reducer,
    [foodContentInfoApi.reducerPath]: foodContentInfoApi.reducer,
  },
  middleware: (getDefaultMiddleware) => {
    return getDefaultMiddleware()
      .concat(authApi.middleware)
      .concat(postApi.middleware)
      .concat(userApi.middleware)
      .concat(restaurantApi.middleware)
      .concat(foodContentInfoApi.middleware);
  },
});

setupListeners(store.dispatch);

export { setUser, setPosts };

export { useRegisterUserMutation, useLoginUserMutation } from './apis/authApi';

export {
  useFetchPostsQuery,
  useAddPostMutation,
  useDeletePostMutation,
  useEditPostMutation,
} from './apis/postApi';

export {
  useFetchQrQuery,
  useFetchParentCategoryByRestaurantQuery,
  useFetchRestaurantByIdQuery,
  useFetchCuisineByCityQuery,
  useFetchRatingsByRestaurantIdQuery,
} from './apis/restaurantApi';

export {
  useFetchUserQuery,
  useFetchNotificationQuery,
  useSubmitContactUsDetailsMutation,
} from './apis/userApi';

export { useFetchFoodContentInfoQuery } from './apis/foodContentInfo';

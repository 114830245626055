/* eslint-disable no-empty */
/* eslint-disable no-unused-vars */
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { URLs } from '../../constants/baseUrls';
// import { setPosts } from "../slices/postSlice";

const BASE_URL = URLs.DEV_URL;

export const restaurantApi = createApi({
  reducerPath: 'post',
  baseQuery: fetchBaseQuery({
    baseUrl: `${BASE_URL}`,
  }),
  endpoints: (builder) => ({
    fetchQr: builder.query({
      query: (data) => {
        return {
          url: 'qr',
          method: 'GET',
          'content-type': 'image/jpeg',
          params: {
            id: data,
          },
        };
      },
      transformResponse: (result) => result,
      async onQueryStarted(data, { dispatch, queryFulfilled }) {
        try {
          const data = await queryFulfilled;
          console.log(data);
        } catch (error) {}
      },
    }),
    fetchRestaurantById: builder.query({
      query: (data) => {
        return {
          url: `restaurant/details/${data}`,
          method: 'GET',
          headers: {
            authorization: `Bearer ${localStorage.getItem('token')}`,
          },
        };
      },
    }),
    fetchCuisineByCity: builder.query({
      query: (data) => {
        return {
          url: `cuisine/all${data !== 'undefined' ? '?city=' + data : ''}`,
          method: 'GET',
          headers: {
            authorization: `Bearer ${localStorage.getItem('token')}`,
          },
        };
      },
    }),
    fetchParentCategoryByRestaurant: builder.query({
      query: (data) => {
        return {
          url: `category/byRestaurant/${data !== 'undefined' ? data : ''}`,
          method: 'GET',
          headers: {
            authorization: `Bearer ${localStorage.getItem('token')}`,
          },
        };
      },
    }),
    fetchRatingsByRestaurantId: builder.query({
      query: (id) => ({
        url: `restaurant/ratings?restaurantId=${id}`,
      }),
      method: 'GET',
      headers: {
        authorization: `Bearer ${localStorage.getItem('token')}`,
      },
    }),
  }),
});

export const {
  useFetchQrQuery,
  useFetchRestaurantByIdQuery,
  useFetchCuisineByCityQuery,
  useFetchParentCategoryByRestaurantQuery,
  useFetchRatingsByRestaurantIdQuery,
} = restaurantApi;

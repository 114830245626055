/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */
import React, { useState } from 'react';
import Foodcard from './Foodcard';
import Button from './Button';
import {
  useAddPostMutation,
  useFetchCuisineByCityQuery,
  useFetchFoodContentInfoQuery,
  useFetchParentCategoryByRestaurantQuery,
} from '../store';
import { convertBase64 } from '../utils/convertBase64';
import { toast } from 'react-toastify';

const CreatePostForm = ({ restaurant, onClose }) => {
  const [foodSubCategory, setFoodSubCategory] = useState([]);

  const {
    data: cuisineOptions,
    error: cuisineOptionsError,
    isFetching,
  } = useFetchCuisineByCityQuery(restaurant?.city?._id);
  const {
    data: parentCategory,
    error: parentCategoryError,
    isFetching: isParentCategoryFetching,
  } = useFetchParentCategoryByRestaurantQuery(restaurant?.id);
  const {
    data: foodContentInfo,
    error: foodContentInfoError,
    isFetching: isFoodContentInfoFetching,
  } = useFetchFoodContentInfoQuery();
  console.log(foodContentInfo);

  const [addPost, { isLoading, isError, error, isSuccess }] = useAddPostMutation();

  const [formData, setFormData] = useState({
    dishName: '',
    restaurant: restaurant?.id,
    restaurantName: restaurant?.name,
    reactionType: 'like',
    parentCategoryName: parentCategory?.name,
    parentCategory: parentCategory?.id,
    base64Image: '',
    price: '',
    foodContentInfo: '',
    foodSubCategory: [],
  });

  const handleFormOnChange = async ({ target: { name, value, files } }) => {
    if (!files) {
      if (name === 'foodContentInfo') {
        setFoodSubCategory(
          foodContentInfo?.foodContentInfo?.filter((element) => element._id === value)[0]
            .subcategories,
        );
      }
      if (name === 'foodSubCategory') {
        setFormData({ ...formData, [name]: [value] });
      } else {
        setFormData({ ...formData, [name]: value });
      }
    } else {
      const base64Image = await convertBase64(files[0]);
      setFormData({ ...formData, [name]: base64Image });
    }
  };

  const handleFormSubmit = async (event) => {
    event.preventDefault();
    const response = await addPost(formData);
    if (response.isSuccess) {
      toast.success('Post created Successfully');
    } else {
      toast.error(response.error.data.message);
    }
  };
  return (
    <div>
      <Foodcard
        dishImage={formData.base64Image || ''}
        dishName={formData.dishName || 'Dish Name'}
        restaurant={formData.restaurantName || 'Restaurant'}
        cuisine={formData.cuisine || 'Cuisine'}
        parent={formData.parentCategoryName || 'Parent'}
        postedBy={formData.createdBy?.username || 'User'}
        price={formData?.price}
      />
      <form>
        <div>
          <input
            className='w-full p-2 border border-[#8C8994] rounded-lg mb-4'
            type='text'
            name='dishName'
            htmlFor='dishName'
            placeholder='Dish Name'
            onChange={handleFormOnChange}
          />
          <label id='dishName' hidden>
            Name
          </label>
        </div>
        <div>
          <input
            className='w-full p-2 border border-[#8C8994] rounded-lg mb-4'
            type='number'
            name='price'
            htmlFor='price'
            placeholder='Price'
            onChange={handleFormOnChange}
          />
          <label id='price' hidden>
            Price
          </label>
        </div>
        <div>
          <select
            className='w-full p-2 border border-[#8C8994] rounded-lg mb-4'
            type='text'
            name='parentCategory'
            htmlFor='parentCategory'
            placeholder='Curries'
            onChange={handleFormOnChange}
          >
            <option>Select Category</option>
            {parentCategory?.categories?.map((element, index) => (
              <option key={index} className='py-2' value={element._id}>
                {element.name}
              </option>
            ))}
          </select>
          <label id='parentCategory' hidden>
            Category
          </label>
        </div>
        <div>
          <select
            className='w-full p-2 border border-[#8C8994] rounded-lg mb-4'
            type='select'
            name='foodContentInfo'
            htmlFor='foodContentInfo'
            placeholder='Food Content Info'
            onChange={handleFormOnChange}
          >
            <option>Select food content info</option>
            {foodContentInfo?.foodContentInfo?.map((element, index) => (
              <option key={index} className='py-2' value={element._id}>
                {element.name}
              </option>
            ))}
          </select>
          <label id='cuisine' hidden>
            Food Content Info
          </label>
        </div>
        {foodSubCategory.length > 0 && (
          <div>
            <select
              className='w-full p-2 border border-[#8C8994] rounded-lg mb-4'
              type='select'
              name='foodSubCategory'
              htmlFor='foodSubCategory'
              placeholder='Food Sub Category'
              onChange={handleFormOnChange}
            >
              <option>Select Sub Category</option>
              {foodSubCategory?.map((element, index) => (
                <option key={index} className='py-2' value={element._id}>
                  {element.name}
                </option>
              ))}
            </select>
            <label id='cuisine' hidden>
              Food Content Info
            </label>
          </div>
        )}
        <div>
          <select
            className='w-full p-2 border border-[#8C8994] rounded-lg mb-4'
            type='select'
            name='cuisine'
            htmlFor='cuisine'
            placeholder='cuisine'
            onChange={handleFormOnChange}
          >
            <option>Select Cuisine</option>
            {cuisineOptions?.cuisines?.map((element, index) => (
              <option key={index} className='py-2' value={element.name}>
                {element.name}
              </option>
            ))}
          </select>
          <label id='cuisine' hidden>
            Cuisine
          </label>
        </div>
        <div>
          <input
            className='w-full p-2 border border-[#8C8994] rounded-lg mb-4'
            type='file'
            name='base64Image'
            htmlFor='base64Image'
            placeholder='Upload Picture'
            onChange={handleFormOnChange}
          />
          <label id='base64Image' hidden>
            File Upload
          </label>
        </div>
        <div className='flex justify-between'>
          <Button
            type='button'
            className='bg-[#8C8994] px-8 text-white rounded-lg py-2'
            label='Cancel'
            onClick={onClose}
          />
          <Button
            className='bg-[#6CB132] px-8 text-white rounded-lg py-2'
            label='Submit'
            onClick={handleFormSubmit}
            isFetching={isLoading}
          />
        </div>
      </form>
    </div>
  );
};

export default CreatePostForm;

import React from 'react';
import Skeleton from 'react-loading-skeleton';

const FoodCardSkeleton = () => {
  return (
    <div className='bg-white w-80 p-4 shadow-lg rounded-lg mb-3'>
      <div className='flex justify-between items-center'>
        <div className='w-1/4 h-full mr-2 rounded'>
          <Skeleton height={60} />
        </div>
        <div className='w-3/4'>
          <h3 className='font-bold'>
            <Skeleton />
          </h3>
          <p>
            <Skeleton />
          </p>
          <p className='price'>
            <Skeleton />
          </p>
        </div>
      </div>
      <div className='mt-1'>
        <Skeleton />
      </div>
    </div>
  );
};

export default FoodCardSkeleton;

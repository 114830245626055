/* eslint-disable react/prop-types */
import React from 'react';
import { AiFillStar } from 'react-icons/ai';

const RestaurantRatingOverview = ({ restaurant, restaurantRatingData }) => {
  const restaurantOverallRating = restaurantRatingData?.overAllRating
    ? parseFloat(restaurantRatingData?.overAllRating).toFixed(1)
    : 0;
  const restaurantCategoriesRatings = restaurantRatingData?.categoriesRatings
    ? restaurantRatingData?.categoriesRatings
    : [];
  const restaurantRateValue = restaurant?.restaurant?.rateValue
    ? restaurant?.restaurant?.rateValue
    : 0;
  return (
    <div className='py-4 border-b text-[#8C8994]'>
      <div className='pb-3'>
        <div className='flex items-center'>
          <h3 className='text-2xl text-[#8C8994] font-bold mr-1'>{restaurantOverallRating}</h3>
          <p className='flex'>
            {new Array(5).fill(0).map((element, index) => (
              <AiFillStar
                key={index}
                className={`mx-0.5 text-2xl text-[${
                  restaurantOverallRating <= index + 1 ? '#8C8994' : '#F1A73C'
                }]`}
              />
            ))}
          </p>
        </div>
        <p className='font-medium'>Based on {restaurantRatingData?.basedOn} ratings</p>
      </div>
      <div>
        {restaurantCategoriesRatings.map((element, index) => (
          <div key={index} className='flex justify-between py-0.5'>
            <span className='text-sm'>{element.name}</span>
            <span className='flex items-center'>
              {new Array(5).fill(0).map((element, index) => (
                <AiFillStar
                  key={index}
                  className={`mx-0.2 text-[${
                    Math.ceil(restaurantRateValue) <= index + 1 ? '#8C8994' : '#F1A73C'
                  }]`}
                />
              ))}{' '}
              <span className='pl-1'>{parseFloat(element.rating).toFixed(1)}</span>
            </span>
          </div>
        ))}
      </div>
    </div>
  );
};

export default RestaurantRatingOverview;

import React, { useEffect, useState } from 'react';
import Button from '../components/Button';
import Header from '../components/Header';
import { callIconContact, emailIconContact, locationIconContact } from '../constants/iconUtils';
import Layout from './../components/Layout';
import { useSubmitContactUsDetailsMutation } from '../store';
import { toast } from 'react-toastify';

const ContactUs = () => {
  const [submitResponse, { isLoading, isError, error, isSuccess }] = useSubmitContactUsDetailsMutation();
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    subject: '',
    message: ''
  })
  const handleFormOnChange = (event) => {
    setFormData({ ...formData, [event.target.name]: event.target.value })
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    submitResponse(formData);
  }

  useEffect(() => {
    if (isSuccess) {
        toast.success("Details submitted successfully");
    }
    if (isError) {
      if (Array.isArray(error.data.error)) {
        error.data.error.forEach((el) =>
          toast.error(el.message, {
            position: "top-right",
          })
        );
      } else {
        toast.error(error.data.message, {
          position: "top-right",
        });
      }
    }
  }, [error, isError, isSuccess]);
  return (
    <Layout>
      <Header title='Contact Us' />
      <div className='bg-white rounded-lg flex justify-between items-center'>
        <div className='w-1/3 ml-14'>
          <h1 className='text-3xl font-bold pb-4'>Get in touch with us</h1>
          <p className='pb-5 text-[#8C8994] font-medium'>
            If ou have any comments, questions, or concerns, feel free to reach out to us. We are
            more than happy to hear from you and we will typically get back to you within 48 hours.
          </p>
          <div>
            <div className='flex items-start my-3'>
              <div className='w-2/12 py-4 border border-[#F1A73C] bg-[#F1A73c44] rounded-md flex justify-center items-center'>
                {locationIconContact}
              </div>
              <div className='w-9/12 ml-3'>
                <h3 className='text-xl font-bold'>Our Location</h3>
                <p className='text-[#8C8994]'>
                  2700 Mission College Blvd #140 #256, Santa Clara, CA 95054
                </p>
              </div>
            </div>
            <div className='flex items-start my-3'>
              <div className='w-2/12 py-4 border border-[#F1A73C] bg-[#F1A73c44] rounded-md flex justify-center items-center text-[#f1A73C]'>
                {callIconContact}
              </div>
              <div className='w-9/12 ml-3'>
                <h3 className='text-xl font-bold'>Phone Number</h3>
                <p className='text-[#8C8994]'>(+91)6357894521</p>
              </div>
            </div>
            <div className='flex items-start my-3'>
              <div className='w-2/12 py-4 border border-[#F1A73C] bg-[#F1A73c44] rounded-md flex justify-center items-center text-[#f1A73C]'>
                {emailIconContact}
              </div>
              <div className='w-9/12 ml-3'>
                <h3 className='text-xl font-bold'>Email</h3>
                <p className='text-[#8C8994]'>admin@foodaholix.com</p>
              </div>
            </div>
          </div>
        </div>
        <div className='w-1/3 mr-14'>
          <form className='px-10 my-12 py-16 shadow-lg rounded-lg border'>
            <div>
              <input
                className='w-full p-2 border border-[#8C8994] rounded-lg mb-4'
                type='text'
                name='name'
                htmlFor='name'
                placeholder='Your Name'
                value={formData.name}
                onChange={handleFormOnChange}
              />
              <label id='name' hidden>
                Your Name
              </label>
            </div>
            <div>
              <input
                className='w-full p-2 border border-[#8C8994] rounded-lg mb-4'
                type='email'
                name='email'
                htmlFor='email'
                placeholder='Email'
                value={formData.email}
                onChange={handleFormOnChange}
              />
              <label id='email' hidden>
                Email
              </label>
            </div>
            <div>
              <input
                className='w-full p-2 border border-[#8C8994] rounded-lg mb-4'
                type='text'
                name='subject'
                htmlFor='subject'
                placeholder='Subject'
                value={formData.subject}
                onChange={handleFormOnChange}
              />
              <label id='subject' hidden>
                Subject
              </label>
            </div>
            <div>
              <textarea
                className='w-full p-2 border border-[#8C8994] rounded-lg mb-4'
                type='text'
                name='message'
                htmlFor='message'
                placeholder='Enter Your Message'
                value={formData.message}
                onChange={handleFormOnChange}
              />
              <label id='message' hidden>
                Enter Your Message
              </label>
            </div>
            <Button
              label='Send Message'
              className='bg-[#6CB132] px-8 text-white rounded-lg py-2 w-full'
              isFetching={isLoading}
              onClick={handleSubmit}
            />
          </form>
        </div>
      </div>
    </Layout>
  );
};

export default ContactUs;

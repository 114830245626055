import React, { useState } from 'react';

import { BsFacebook } from 'react-icons/bs';
import { FcGoogle } from 'react-icons/fc';
import { BsApple } from 'react-icons/bs';
import Button from '../components/Button';
import background from '../assets/login/loginbg.svg';

const SignUpPage = () => {
  const [signUp, setSignUp] = useState('user');
  const restaurantSignUp = signUp === 'restaurant';

  const handleChange = (event) => {
    setSignUp(event.target.value);
  };

  return (
    <div className='flex justify-center w-full' style={{ backgroundImage: `url(${background})` }}>
      <div className='p-4 my-10 bg-[#FFFFFF] rounded-lg'>
        <h3 className='text-3xl font-bold text-black my-3'>Sign Up</h3>
        <div className='form-container'>
          <form className='w-80'>
            <div className='flex justify-between mb-2'>
              <div>
                <input
                  className='radio-input'
                  type='radio'
                  name='user'
                  htmlFor='user'
                  value='user'
                  checked={signUp === 'user'}
                  onChange={(event) => handleChange(event)}
                />
                <label id='user' className='ml-2'>
                  User
                </label>
              </div>
              <div>
                <input
                  className='radio-input'
                  type='radio'
                  name='restaurant'
                  htmlFor='restaurant'
                  value='restaurant'
                  checked={signUp === 'restaurant'}
                  onChange={(event) => handleChange(event)}
                />
                <label id='restaurant' className='ml-2'>
                  Restaurant Owner
                </label>
              </div>
            </div>

            <div className='username'>
              <input
                className='w-full p-2 border border-[#8C8994] rounded-lg mb-4'
                type='text'
                name='username'
                htmlFor='username'
                placeholder='Username'
              />
              <label id='username' hidden>
                Username
              </label>
            </div>

            <div className='password'>
              <input
                className='w-full p-2 border border-[#8C8994] rounded-lg mb-4'
                type='password'
                name='password'
                htmlFor='password'
                placeholder='Password'
              />
              <label id='password' hidden>
                Password
              </label>
            </div>

            {!restaurantSignUp && (
              <div className='referral'>
                <input
                  className='w-full p-2 border border-[#8C8994] rounded-lg mb-4'
                  type='text'
                  name='referral'
                  htmlFor='referral'
                  placeholder='Referral Code'
                />
                <label id='referral' hidden>
                  Referral Code
                </label>
              </div>
            )}

            {restaurantSignUp && (
              <div className='email'>
                <input
                  className='w-full p-2 border border-[#8C8994] rounded-lg mb-4'
                  type='email'
                  name='email'
                  htmlFor='email'
                  placeholder='Email'
                />
                <label id='email' hidden>
                  Email
                </label>
              </div>
            )}

            {restaurantSignUp && (
              <div className='restaurant-name'>
                <input
                  className='w-full p-2 border border-[#8C8994] rounded-lg mb-4'
                  type='text'
                  name='restaurantName'
                  htmlFor='restaurantName'
                  placeholder='Restaurant Name'
                />
                <label id='restaurantName' hidden>
                  Restaurant Name
                </label>
              </div>
            )}

            <div className='city'>
              <input
                className='w-full p-2 border border-[#8C8994] rounded-lg mb-4'
                type='text'
                name='city'
                htmlFor='city'
                placeholder='Preferred City'
              />
              <label id='city' hidden>
                Preferred City
              </label>
            </div>

            <Button
              label='Sign Up'
              className='bg-[#6CB132] w-full p-3 my-4 rounded-lg'
              type='submit'
              color='white'
            />
          </form>
          <div className='flex justify-around items-center text-xl text-[#8C8994]'>
            <hr className='w-1/3 border border-[#8C8994]' />
            Or
            <hr className='w-1/3 border border-[#8C8994]' />
          </div>
          {!restaurantSignUp && (
            <Button
              className='w-full p-3 my-2 rounded-lg flex justify-center items-center shadow-lg text-[#8C8994]'
              label='Sign Up with Apple'
              leftIcon={<BsApple className='mr-2 text-black' size={25} />}
            />
          )}
          {!restaurantSignUp && (
            <Button
              className='w-full p-3 my-2 rounded-lg flex justify-center items-center shadow-lg text-[#8C8994]'
              label='Sign Up with Google'
              leftIcon={<FcGoogle className='mr-2 text-black' size={25} />}
            />
          )}
          {!restaurantSignUp && (
            <Button
              className='w-full p-3 my-2 rounded-lg flex justify-center items-center shadow-lg text-[#8C8994]'
              label='Sign Up with Facebook'
              leftIcon={<BsFacebook className='mr-2 text-[#1877F2]' size={25} color='blue' />}
            />
          )}
          <a href='/login' className='flex justify-center items-center my-3 mt-5 text-[#F1A73C]'>
            Already have an account? <span className='font-bold ml-2'>Login</span>
          </a>
        </div>
      </div>
    </div>
  );
};

export default SignUpPage;
